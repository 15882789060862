html, body {touch-action: pan-y; overflow-y: hidden; background-color: #ffffff;}

.App {
  text-align: center;
  background-color: #ffffff;
  color: #252525;
  margin-top: 110px;
}

hr {
  background-color: #909090;
  color: #909090;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.camera-block {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  /* user-select:none; */
  /* -webkit-tap-highlight-color:rgba(0,0,0,0); */
  position: fixed;
  bottom: 20px;
  width: 100%;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.post-time-ago {
  /* flex-direction: column;
  display: flex; */
  /* width: 100%; */
  text-align: right;
  font-size: 14px;
  /* position: relative; */
  /* bottom: 40px; */
  right: 20px;
  margin-top:-30px;
  /* height: 42px; */
  display: flex;
  flex-direction: column;
  flex: 0.3;
  padding: 5px;
}

.recording {
  border-radius: 20px;
  background-color: #f42020;
  width: 100px;
  padding: 12px;
  color: #f0f0f0;
  font-weight: bold;
  margin-left: 10px;
}

.buttonbar {
  display: flex;
  flex-direction: row;
  flex: 5;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.buttonwrapper {
  display: inline-flex;
  flex: 1;
}


.whitecircle {
  /* margin-top: -56px;
  margin-left: 14px; */
  padding: 8px;
  display: flex;
  flex: 0.4;
  border-radius: 22px;
  border: solid 2px #ffffff;
  width: 24px;
  height: 24px;
  /* position: relative; */
  z-index: 991;
}

.blackcircle {
  padding: 8px;
  border-radius: 22px;
  border: solid 2px #050505;
  width: 24px;
  height: 24px;
}

.timeline-spacer-top {
  height: 55px;
  width: 100%;
}

.timeline-spacer-medium {
  height: 85px;
  width: 100%;
}

.post-text-input {
  border: solid 1px #050505;
  align-self: center;
  width: 190px;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
}

.timeline-spacer-bottom {
  height: 150px;
  width: 100%;
}

.fullblackcircle {
  padding: 8px;
  border-radius: 22px;
  border: solid 2px #050505;
  background-color: #050505;
  width: 24px;
  height: 24px;
}

.comment-input {
  font-size: 16px;
  border-radius: 8px;
  border: solid 1px #080808;
}

.search {
  position: fixed;
  width: 100%;
  top: 150px;
  z-index: 99999;
  opacity: 1;
  background-color: #ffffff;
  padding: 4px;
}

.search-input {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 6px;
  border: solid 1px #050505;
  font-size: 16px;
}

.splash {
  z-index: 9999999999999;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  position: absolute;
  top: 0px;
  flex-direction: column;
  justify-content: center;
}

.mainbutton {
  z-index: 999999;
  left: 50%;
  position: fixed;
  width: 95px;
  bottom: -4px;
  transform: translate(-50%, 0);
}

.tip {
  z-index: 9999999;
  left: 50%;
  position: fixed;
  background-color: #d0cff5;
  border: solid 1px #0f0f0f;
  border-radius: 12px;
  color: #050505;
  width: 250px;
  bottom: 85px;
  padding: 10px;
  transform: translate(-50%, 0);
}

.cancel-icon-preview {
  padding-top: 50px;
  width: 58px;
  height: 58px;
  left: 22%;
  position: fixed;
  transform: translate(-50%, 0);
}

.cambutton {
  width: 140px;
  display: flex;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}

.llbutton {
  border: solid 1px #2f2f2f;
  border-radius: 6px;
  padding: 6px;
  display: inline-block;
  font-size: 16px;
  color: #050505;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.camera-button {
  background-color: #43e122;
  z-index: 9999;
  width: 60px;
  height: 22px;
  position: absolute;
  right: 40px;
  opacity: 1;
}

.post {
  max-width: 98%;
  height: auto;
  width: auto;
  color: #efefef;
}

.geobar {
  position: fixed;
  width: 100%;
  padding: 6px;
  padding-left: 30px;
  top: 40px;
  opacity: 0.5;
  background-color: #353535;
  color: #cccccc;
  font-size: 14px;
  align-items: left;
  text-align: left;
}

.header {
  position: fixed;
  padding: 8px;
  padding-top: 14px;
  top: 0px;
  width: 100%;
  background-color: #ffffff;
  z-index: 992;
  /* -webkit-mask-image: -webkit-gradient(linear, left bottom, left top,
    from(rgba(255,255,255,0)), to(rgba(255,255,255,1))); */
}

.post-image {
  border-radius: 22px;
  width: 98%;
  height: auto;
  margin-left: 7px;
}

.menu {
  position: absolute;
  top: 50px;
  left: 8px;
  z-index: 999999999;
  border-radius: 4px;
  background-color: #f0f0f0;
  opacity: 0.9;
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #5f5f5f;
  padding: 10px;
  align-items: left;
  text-align: left;
}

.minipost {
  border-bottom: solid 1px #cccccc;
  padding: 12px;
  display: flex;
  flex-direction: row;
}

.history {
  display: flex;
  flex-direction: column;
}

.post-bottom {
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: inherit;
}

.post-main {
  display: flex;
  flex-direction: column;
  position: relative;
}

.post-bottom-left {
  padding-left: 13px;
  position: relative;
  display: inline;
  flex-direction: column;
  width: 142px;
  align-self: flex-end;
  margin-bottom: -4px;
}

.post-bottom-right {
  text-align: right;
  padding-right: 10px;
  align-self: end;
  width: 100%;
}

.message-div {
  z-index: 999999;
  left: 30px;
  right: 30px;
  height: 300px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 140px;
  position: fixed;
  text-align: center;
  top: 180px;
  font-size: 20px;
  font-weight: bold;
  color: #090909;
  opacity: 0.5;
  background-color: #b0b0b0;
}

.map {
  position: absolute;
  z-index: 9999999;
  top: 140px;
  margin-left: 2px;
}

.leaflet-marker-icon {
  background-color: #000000;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: #505050;
  box-shadow: 0 3px 14px rgba(0,0,0,.4);
  color: #333;
}

.leaflet-container .leaflet-control-attribution {
  background: #101010;
  color: #f0f0f0;
  border-radius: 8px;
}

.leaflet-container, .leaflet-control-attribution {
  background: #101010;
  color: #f0f0f0;
  border-radius: 8px;
}
